<template>
    <el-dialog v-model="dialog_opened" width="90%" :before-close="close">
        <el-row v-loading="loading">
            <el-col :span="11">
                <div class="canvas" id="canvas">
                    <canvas ref="new" style="width: 100%" id="fore" v-loading="loading"></canvas>
                    <canvas ref="canvas" class="layer" v-loading="loading"></canvas>
                </div>
            </el-col>
            <el-col :span="2" style="text-align: center;">
                <el-divider direction="vertical" style="height: 100%"></el-divider>
            </el-col>
            <el-col :span="11" v-if="data">
                <p>共 {{ data.colorGroup.length }} 种颜色；共 {{ Object.keys(data.cells).length }} 个色块。</p>
                <el-radio-group v-model="mode" :disabled="loading" @change="changeMode">
                    <el-radio :label="1">修改颜色</el-radio>
                    <el-radio :label="2">合并色块</el-radio>
                    <el-radio :label="3">合并色盘</el-radio>
                </el-radio-group>
                <div v-if="mode === 1">
                    <el-color-picker v-model="color" :disabled="!color || loading"
                                     @change="changeColor"></el-color-picker>
                    {{ color }}
                </div>
                <div v-else-if="mode === 2">
                    <el-switch v-model="small" :inactive-value="false" :active-value="true" inactive-text="不显示"
                               active-text="显示小色块" @change="changeSmall"></el-switch>
                    <el-button style="margin-left: 10px" type="primary" :disabled="merge.length < 2 || loading"
                               @click="mergeCells">
                        合并 {{ merge.length }} 个色块
                    </el-button>
                </div>
                <div v-else-if="mode === 3">
                    <el-slider v-model="slider" :disabled="loading" :min="1" :max="100" @change="changeSlider"></el-slider>
                </div>
                <div v-if="[1,2].includes(mode)">
                    <div v-for="(g, i) in data.colorGroup" class="circle" @click="clickGroup(i)"
                         :class="active > -1 && active === i?'active':''"
                         :style="{backgroundColor: g.color, color: calColor(g.color)}">
                        {{ g.regions.length }}
                    </div>
                </div>
                <div v-else-if="mode === 3">
                    <div>合并后共 {{group_merge.length}} 种颜色</div>
                    <div v-for="(group, j) in group_merge" style="display: inline-block"
                         :style="{backgroundColor: group.length < 2?'':`var(--el-color-warning-light-${j % 3 * 2 + 3})`}">
                        <div v-for="i in group" class="circle" @click="clickMerge(i)"
                             :class="i === active_color ? 'active': ''"
                             :style="{backgroundColor: data.colorGroup[i].color, color: calColor(data.colorGroup[i].color)}">
                            {{ i + 1 }}
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <template #footer>
            <el-button type="primary" text @click="close" :loading="loading">取消</el-button>
            <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import animationData from "../assets/marker.json";
import lottie from "lottie-web";
import {ElMessageBox} from 'element-plus'

export default {
    name: "Test",
    data() {
        return {
            loading: false, dialog_opened: false, small: false, image: null, data: null, worker: null, map: null,
            mode: 1, dragging: false, active: -1, color: null, selected: [], start: null, merge: [],
            small_selected: [], map_canvas: null, slider: 10, group_merge: [], active_color: -1,
        }
    },
    methods: {
        init(image, abtest) {
            this.dialog_opened = this.loading = true;
            this.image = image;
            this.abtest = abtest
            axios.get(`/cms/v1/test/image/${image.id}/map?abtest={${this.abtest}`).then(res => {
                this.data = res.data.data.data;
                const canvas = this.$refs.canvas;
                const image = new Image();
                image.src = `data:image/webp;base64,${res.data.data.mask}`;
                image.onload = _ => {
                    const map = document.createElement('canvas');
                    const ctx = map.getContext('2d');
                    map.width = canvas.width = image.width;
                    map.height = canvas.height = image.height;
                    ctx.drawImage(image, 0, 0);
                    this.map = ctx;
                    this.map_canvas = map;
                    const parent = document.getElementById('canvas');
                    parent.style.height = parent.clientWidth / canvas.width * canvas.height + 'px';
                    requestAnimationFrame(this.animate);
                }
                const fore = new Image();
                const new_canvas = this.$refs.new;
                fore.src = `data:image/webp;base64,${res.data.data.finish}`;
                fore.onload = _ => {
                    new_canvas.width = fore.width;
                    new_canvas.height = fore.height;
                    const ctx = new_canvas.getContext('2d');
                    ctx.drawImage(fore, 0, 0);
                }
                this.loading = false;
                canvas.addEventListener('click', this.click);
                canvas.addEventListener('mousedown', this.mousedown);
                canvas.addEventListener('mousemove', this.mousemove);
                canvas.addEventListener('mouseup', this.mouseup);
                // this.draw();
            });
            document.addEventListener('keydown', this.rollback);
        },
        animate(currentTime) {
            if (!this.start) {
                this.start = currentTime;
            }
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            const elapsedTime = currentTime - this.start;
            const duration = 1000;
            const progress = (elapsedTime % duration) / duration;

            // 计算当前颜色的透明度
            const alpha = Math.abs(Math.sin(progress * Math.PI));
            const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < data.data.length; i += 4) {
                data.data[i + 3] = 0;
            }
            this.selected.forEach(i => {
                data.data[i] = 255;
                data.data[i + 1] = 255;
                data.data[i + 2] = 255;
                data.data[i + 3] = Math.round(alpha * 255);
            });
            this.small_selected.forEach(i => {
                data.data[i] = 255;
                data.data[i + 1] = 255;
                data.data[i + 2] = 255;
                data.data[i + 3] = Math.round(alpha * 255);
            });
            ctx.putImageData(data, 0, 0);
            if (this.dialog_opened)
                requestAnimationFrame(this.animate);
        },
        rollback(e) {
            if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
                if (this.mode === 2 && this.merge.length > 0) {
                    const canvas = this.$refs.canvas;
                    const map_data = this.map.getImageData(0, 0, canvas.width, canvas.height).data;
                    const index = this.merge.splice(this.merge.length - 1, 1)[0];
                    const cancel = [];
                    for (let i = 0; i < map_data.length; i += 4) {
                        if (map_data[i] === (index >> 16) % 256 && map_data[i + 1] === (index >> 8) % 256 && map_data[i + 2] === index % 256) {
                            cancel.push(i);
                        }
                    }
                    const selected = this.selected;
                    this.selected = [];
                    selected.forEach(s => {
                        if (!cancel.includes(s)) {
                            this.selected.push(s);
                        }
                    })
                    if (this.selected.length < 1) {
                        this.active = -1;
                        this.color = null;
                    }
                }
            }
        },
        click(e) {
            if (this.dragging)
                return;
            const canvas = this.$refs.canvas;
            const rect = canvas.getBoundingClientRect();
            const offset_x = rect.left;
            const offset_y = rect.top;
            const x = Math.round((e.clientX - offset_x) * canvas.width / canvas.clientWidth);
            const y = Math.round((e.clientY - offset_y) * canvas.height / canvas.clientHeight);
            const i = canvas.width * y * 4 + x * 4;
            const map_data = this.map.getImageData(0, 0, canvas.width, canvas.height).data;
            const index = map_data[i + 2] + map_data[i + 1] * 256 + map_data[i] * 256 ** 2;
            if (this.mode === 1) {
                if (this.selected.includes(i)) {
                    const cancel = [];
                    for (let i = 0; i < map_data.length; i += 4) {
                        if (map_data[i] === (index >> 16) % 256 && map_data[i + 1] === (index >> 8) % 256 && map_data[i + 2] === index % 256) {
                            cancel.push(i);
                        }
                    }
                    const selected = this.selected;
                    this.selected = [];
                    selected.forEach(s => {
                        if (!cancel.includes(s)) {
                            this.selected.push(s);
                        }
                    })
                    if (this.selected.length < 1) {
                        this.active = -1;
                        this.color = null;
                    }
                } else {
                    let valid = true;
                    this.data.colorGroup.forEach((g, i) => {
                        if (g.regions.includes(index)) {
                            if (this.active > -1) {
                                if (this.active !== i)
                                    valid = false;
                            } else {
                                this.active = i
                            }
                        }
                    });
                    if (valid) {
                        this.color = this.data.colorGroup[this.active].color;
                        for (let i = 0; i < map_data.length; i += 4) {
                            if (map_data[i] === (index >> 16) % 256 && map_data[i + 1] === (index >> 8) % 256 && map_data[i + 2] === index % 256) {
                                this.selected.push(i);
                            }
                        }
                    }
                }
            } else if (this.mode === 2) {
                if (this.merge.includes(index)) {
                } else {
                    let valid = false;
                    if (this.active > -1) {
                        for (let i = 0; i < map_data.length; i += 4) {
                            if (map_data[i] === (index >> 16) % 256 && map_data[i + 1] === (index >> 8) % 256 && map_data[i + 2] === index % 256) {
                                if (i % canvas.width >= 4 & map_data[i - 4] === (this.merge[0] >> 16) % 256 && map_data[i - 3] === (this.merge[0] >> 8) % 256 && map_data[i - 2] === this.merge[0] % 256) {
                                    valid = true;
                                } else if (i % canvas.width + 4 < canvas.width && map_data[i + 4] === (this.merge[0] >> 16) % 256 && map_data[i + 5] === (this.merge[0] >> 8) % 256 && map_data[i + 6] === this.merge[0] % 256) {
                                    valid = true;
                                } else if (i / 4 / canvas.width >= 1 & map_data[i - 4 * this.width] === (this.merge[0] >> 16) % 256 && map_data[i - 4 * this.width + 1] === (this.merge[0] >> 8) % 256 && map_data[i - 4 * this.width + 2] === this.merge[0] % 256) {
                                    valid = true;
                                } else if (i / 4 / canvas.width + 1 < canvas.height && map_data[i + 4 * this.width] === (this.merge[0] >> 16) % 256 && map_data[i + 4 * this.width + 1] === (this.merge[0] >> 8) % 256 && map_data[i + 4 * this.width + 2] === this.merge[0] % 256) {
                                    valid = true;
                                }
                            }
                        }
                        if (valid) {
                            this.merge.push(index);
                        }
                    } else {
                        this.data.colorGroup.forEach((g, i) => {
                            if (g.regions.includes(index)) {
                                this.active = i
                                this.merge = [index];
                                valid = true;
                            }
                        });
                    }
                    if (valid) {
                        for (let i = 0; i < map_data.length; i += 4) {
                            if (map_data[i] === (index >> 16) % 256 && map_data[i + 1] === (index >> 8) % 256 && map_data[i + 2] === index % 256) {
                                this.selected.push(i);
                            }
                        }
                    }
                }
            }
        },
        mousedown(e) {

        },
        mousemove(e) {

        },
        mouseup(e) {

        },
        mergeCells() {
            ElMessageBox.confirm(`确定要合并这${this.merge.length}个色块吗？`, '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning'
            }).then(_ => {
                const cell = this.data.cells[this.merge[0]];
                const canvas = this.$refs.canvas;
                const data = this.map.getImageData(0, 0, canvas.width, canvas.height);
                const map_data = data.data;
                const new_canvas = this.$refs.new;
                const ctx = new_canvas.getContext('2d');
                const new_data = ctx.getImageData(0, 0, canvas.width, canvas.height);
                this.merge.forEach((m, i) => {
                    if (i) {
                        const discard = this.data.cells[m];
                        let right = cell.topLeft[0] + cell.width;
                        if (right < discard.topLeft[0] + discard.width) {
                            right = discard.topLeft[0] + discard.width
                        }
                        let bottom = cell.topLeft[1] + cell.height;
                        if (bottom < discard.topLeft[1] + discard.height) {
                            bottom = discard.topLeft[1] + discard.height
                        }
                        if (cell.topLeft[0] > discard.topLeft[0]) {
                            cell.topLeft[0] = discard.topLeft[0];
                        }
                        if (cell.topLeft[1] > discard.topLeft[1]) {
                            cell.topLeft[1] = discard.topLeft[1];
                        }
                        cell.width = right - cell.topLeft[0];
                        cell.height = bottom - cell.topLeft[1];

                        for (let i = 0; i < map_data.length; i += 4) {
                            if (map_data[i] === (m >> 16) % 256 && map_data[i + 1] === (m >> 8) % 256 && map_data[i + 2] === m % 256) {
                                map_data[i] = (this.merge[0] >> 16) % 256;
                                map_data[i + 1] = (this.merge[0] >> 8) % 256;
                                map_data[i + 2] = this.merge[0] % 256;
                                if ([1].includes(this.image.type)) {
                                    new_data.data[i] = cell.avgColor[0];
                                    new_data.data[i + 1] = cell.avgColor[1];
                                    new_data.data[i + 2] = cell.avgColor[2];
                                    new_data.data[i + 3] = 255;
                                }
                            }
                        }
                        delete this.data.cells[m];
                        let n = -1;
                        this.data.colorGroup.forEach((g, i) => {
                            if (g.regions.includes(m)) {
                                g.regions.splice(g.regions.indexOf(m), 1);
                                if (g.regions.length < 1) {
                                    n = i;
                                }
                            }
                        });
                        if (n > -1) {
                            this.data.colorGroup.splice(i, 1);
                        }
                    }
                });
                ctx.putImageData(new_data, 0, 0);
                this.map.putImageData(data, 0, 0);
                this.merge = [];
                this.selected = [];
                this.active = -1;
            }).catch(_ => {
            })
        },
        changeColor() {
            const canvas = this.$refs.new;
            const ctx = canvas.getContext('2d');
            const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const color = this.color.toLowerCase()
            let group = null, exists = false;
            this.data.colorGroup.forEach(g => {
                if (g.color === color) {
                    group = g;
                    exists = true;
                }
            })
            if (!group)
                group = {color, regions: []};
            const regions = this.data.colorGroup[this.active].regions;
            const map_data = this.map.getImageData(0, 0, canvas.width, canvas.height).data;
            this.selected.forEach(i => {
                const index = map_data[i + 2] + map_data[i + 1] * 256 + map_data[i] * 256 ** 2;
                data.data[i] = parseInt(this.color.slice(1, 3), 16);
                data.data[i + 1] = parseInt(this.color.slice(3, 5), 16);
                data.data[i + 2] = parseInt(this.color.slice(5, 7), 16);
                data.data[i + 3] = 255;
                if (!group.regions.includes(index)) {
                    group.regions.push(index);
                    regions.splice(regions.indexOf(index), 1);
                }
            })
            if (!exists)
                this.data.colorGroup.push(group);
            if (regions.length < 1) {
                this.data.colorGroup.splice(this.active, 1);
            }
            this.active = this.data.colorGroup.length - 1;
            ctx.putImageData(data, 0, 0);
            this.selected = [];
        },
        close() {
            this.cleanMarker();
            this.dialog_opened = this.loading = this.small = this.start = false;
            this.$nextTick(_ => {
                this.image = this.map = this.data = this.color = this.map_canvas = null;
                this.active = this.active_color = -1;
                this.mode = 1;
                this.selected = [];
                this.merge = [];
                this.group_merge = [];
            });
            document.removeEventListener('keydown', this.rollback);
        },
        changeMode() {
            this.cleanMarker();
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            this.small = false;
            this.selected = [];
            this.merge = [];
            this.active = this.active_color = -1;
            this.color = null;
            if (this.mode === 3) {
                this.changeSlider();
            }
        },
        changeSlider() {
            this.group_merge = [];
            const exists = [];
            for (let i = 0; i < this.data.colorGroup.length; i++) {
                if (exists.includes(i))
                    continue;
                const group = [i];
                exists.push(i);
                for (let j = i + 1; j < this.data.colorGroup.length; j++) {
                    const distance = this.getColorDifference(this.data.colorGroup[i].color, this.data.colorGroup[j].color);
                    if (distance < this.slider) {
                        group.push(j);
                        exists.push(j);
                    }
                }
                this.group_merge.push(group);
            }
        },
        calColor(color) {
            const {r, g, b} = this.hexToRgb(color);
            return r * 0.299 + g * 0.587 + b * 0.144 >= 128 ? '#606266' : '#EBEEF5';
        },
        drawCircle(cell) {
            const canvas = this.$refs.canvas;
            const fore = document.getElementById('fore');
            const width = fore.parentElement.clientWidth;
            const height = fore.parentElement.clientHeight;
            const marker = document.createElement('div');
            marker.className = 'marker mask-marker';
            marker.style.position = 'absolute';
            marker.style.zIndex = '999';
            const x = cell.textPos[0] + cell.topLeft[0];
            const y = cell.textPos[1] + cell.topLeft[1];

            const canvas_width = canvas.width;
            const canvas_height = canvas.height;
            marker.style.left = (x / canvas_width * width - 50) + 'px';
            marker.style.top = (y / canvas_height * height - 50) + 'px';
            marker.style.width = '100px';
            lottie.loadAnimation({
                container: marker,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData,
            });
            canvas.insertAdjacentElement('afterend', marker);
        },
        cleanMarker() {
            document.querySelectorAll('.mask-marker').forEach(e => {
                e.parentElement.removeChild(e);
            });
        },
        changeSmall() {
            this.small_selected = [];
            this.cleanMarker();
            if (this.small) {
                const small = [];
                const canvas = this.$refs.canvas;
                const map_data = this.map.getImageData(0, 0, canvas.width, canvas.height).data;
                Object.keys(this.data.cells).forEach(k => {
                    const cell = this.data.cells[k];
                    if (cell.textSize < 15) {
                        small.push(parseInt(k));
                        this.drawCircle(cell);
                    }
                });
                for (let i = 0; i < map_data.length; i += 4) {
                    if (small.includes(map_data[i] * 256 ** 2 + map_data[i + 1] * 256 + map_data[i + 2])) {
                        this.small_selected.push(i);
                    }
                }
            }
        },
        clickMerge(i) {
            this.selected = [];
            if (this.active_color !== i) {
                this.active_color = i;
                const canvas = this.$refs.canvas;
                const map_data = this.map.getImageData(0, 0, canvas.width, canvas.height).data;
                const regions = this.data.colorGroup[i].regions;
                for (let i = 0; i < map_data.length; i += 4) {
                    if  (regions.includes(map_data[i] * 256 ** 2 + map_data[i + 1] * 256 + map_data[i + 2])) {
                        this.selected.push(i);
                    }
                }
            } else {
                this.active_color = -1;
            }
        },
        clickGroup(i) {
            if (this.active < 0) {
                this.active = i;
                this.color = this.data.colorGroup[i].color;
            } else if (this.active === i) {
                this.active = -1;
                this.color = null;
                this.merge = [];
                this.selected = [];
            }
        },
        getColorDifference(color1, color2) {
            const rgb1 = this.hexToRgb(color1);
            const rgb2 = this.hexToRgb(color2);
            const rDiff = rgb1.r - rgb2.r;
            const gDiff = rgb1.g - rgb2.g;
            const bDiff = rgb1.b - rgb2.b;
            return Math.sqrt(rDiff * rDiff + gDiff * gDiff + bDiff * bDiff);
        },
        hexToRgb(hex) {
            const r = parseInt(hex.substring(1, 3), 16);
            const g = parseInt(hex.substring(3, 5), 16);
            const b = parseInt(hex.substring(5, 7), 16);
            return {r, g, b};
        },
        submit() {
            this.loading = true;
            const form = new FormData();
            form.append('data', JSON.stringify(this.data));
            this.map_canvas.toBlob(blob => {
                form.append('file', blob, 'image.png')
                if ([1].includes(this.image.type)) {
                    const canvas = this.$refs.new;
                    canvas.toBlob(b => {
                        form.append('file1', b, 'finish.png')
                        this._submit(form);
                    });
                } else {
                    this._submit(form)
                }
            })
        },
        _submit(form) {
            axios.post(`/cms/v1/test/image/${this.image.id}/map?abtest=${this.abtest}`, form).then(res => {
                this.image.changed = true;
                this.close();
            });
        }
    },
}
</script>

<style scoped>
.canvas {
    position: relative;
    background-color: #FFF;
    overflow: hidden;
}

.layer {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.circle {
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    display: inline-block;
}

.circle.active {
    font-size: 18px;
    margin: 0;
    border: 5px solid var(--el-color-primary);
}
</style>